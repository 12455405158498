const initialUserState = {
  email: '',
  password: '',
  token: '',
};

const initialUserMessageState = {
  showUserMessage: false,
  isErrorMessage: false,
  message: ''


}
export {initialUserMessageState, initialUserState}